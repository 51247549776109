<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items flex-wrap">
          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">预约时间: </span>
            <div class="width300">
              <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="~"
                  @change="handleDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.cityId" clearable placeholder="市" @change="handleCity">
              <el-option v-for="(item,index) in city" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.countyId" clearable placeholder="区县">
              <el-option v-for="(item,index) in county" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.type" clearable>
              <el-option label="类型" value=""></el-option>
              <el-option label="二手房" :value="1"></el-option>
              <el-option label="办公租赁" :value="2"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.state" clearable>
              <el-option label="带看状态" value=""></el-option>
              <el-option label="未带看" :value="1"></el-option>
              <el-option label="带看中" :value="2"></el-option>
              <el-option label="已带看" :value="3"></el-option>
              <el-option label="已取消" :value="4"></el-option>
            </el-select>
          </div>

          <el-input size="medium" v-model="searchData.name" ref="searchInput" placeholder="输入手机号/房源号/房源名称" :maxlength="100" class="marginRight10 width300 marginBottom10"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>

      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-view" @click="handleView(row)">查看</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleRemarks(row)">备注</el-button>
<!--带看记录-->
        <el-button type="text" size="mini" icon="el-icon-view" @click="handleRecord(row)">带看记录</el-button>
      </template>
    </avue-crud>


    <el-dialog
        title="备注"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="35%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="handleSubmit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

  </div>
</template>

<script>
import {list, remark} from "@/api/house/view"
import { listCity, listCounty} from "@/api/house/second";
import qs from "qs";
export default {
  name: "index",
  data(){
    return{
      city: [],
      county: [],
      date: [],
      searchData: {
        subscribeTimeStart: '',
        subscribeTimeEnd: '',
        cityId: '',
        countyId: '',
        type: '', // 1-二手房；2-办公租赁
        state: '', // 带看状态：1-未带看；2-带看中；3-已带看；4-已取消,
        name: '', // 手机号/房源号/房源名称
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '看房表单管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: true, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '客户姓名',
            width: 150,
            prop: 'username'
          },
          {
            label: '客户手机号',
            width: 150,
            prop: 'phone'
          },
          {
            label: '预约时间',
            width: 200,
            prop: 'subscribeTime'
          },
          {
            label: '类型',
            width: 150,
            prop: 'type',
            type: 'radio',
            dicData: [
              { label: '二手房', value: 1 },
              { label: '办公租赁', value: 2 }
            ]
          },
          {
            label: '房源号',
            width: 200,
            prop: 'code'
          },
          {
            label: '房源名称',
            width: 200,
            prop: 'title'
          },
          {
            label: '城市',
            width: 150,
            prop: 'city'
          },
          {
            label: '区县',
            width: 150,
            prop: 'county'
          },
          {
            label: '商圈',
            width: 200,
            prop: 'business'
          },
          {
            label: '楼盘名称',
            width: 200,
            prop: 'name'
          },
          {
            label: '带看状态',
            width: 150,
            prop: 'state',
            type: 'radio',
            dicData: [
              { label: '未带看', value: 1 },
              { label: '带看中', value: 2 },
              { label: '已带看', value: 3 },
              { label: '已取消', value: 4 }
            ]
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: '',
        remark: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '备注',
            prop: 'remark',
            type: 'textarea',
            maxlength: 1000,
            showWordLimit: true,
            span: 24,
            row: true,
            rules: [
              { required: true, message: '请输入备注', trigger: 'blur' }
            ]
          }
        ]
      }
    }
  },
  async mounted() {
    await this.getListCity();
  },
  methods: {
    async getListCity(){
      const { data } = await listCity();
      // console.log(data);
      this.city = data || [];
    },
    async getListCounty(id){
      const { data } = await listCounty({
        id: id
      });
      this.county = data || [];
    },
    // 选择市
    async handleCity(e){
      this.searchData.countyId = '';
      this.county = [];
      await this.getListCounty(e);
    },
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 选择时间
    handleDate(val){
      console.log(val)
      if(val.length > 0){
        this.searchData.payTimeStart = val[0] + ' 00:00:00'
        this.searchData.payTimeEnd = val[1] + ' 23:59:59'
      }else{
        this.searchData.payTimeStart = '';
        this.searchData.payTimeEnd = ''
      }
    },
    // 查看
    handleView(row){
      // console.log(row)
      this.$router.push({
        path: '/house/view/detail',
        query: {
          id: row.id
        }
      })
    },
    // 备注
    handleRemarks(row){
      this.dialogVisible = true;
      this.form.id = row.id;
    },
    handleSubmit(form,done){
      // console.log(form)
      remark(form).then(res => {
        if(res.code === 200){
          this.$message.success('备注成功');
          this.dialogVisible = false;
          this.form = {
            id: '',
            remark: ''
          }
          this.onLoad();
        }else{
          this.$message.error(res.msg);
          done()
        }
      }).catch(() => {
        done()
      })
    },
    handleClose(){
      this.dialogVisible = false;
      this.form = {
        id: '',
        remark: ''
      }
    },
    // 带看记录
    handleRecord(row){
      this.$router.push({
        path: '/house/view/record',
        query: {
          id: row.id
        }
      })
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        subscribeTimeStart: '',
        subscribeTimeEnd: '',
        cityId: '',
        countyId: '',
        type: '', // 1-二手房；2-办公租赁
        state: '', // 带看状态：1-未带看；2-带看中；3-已带看；4-已取消,
        name: '', // 手机号/房源号/房源名称
      }
      this.county = [];
      this.date = [];
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 导出
    handleExport(){
      // console.log(qs.stringify(this.searchData))
      window.open(`/api/web/house/view/export?${qs.stringify(this.searchData)}`,'_self');
    }
  }
}
</script>

<style scoped>
/deep/ .avue-crud .avue-crud__tip{
  display: none;
}
</style>